/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
const GoogleAnalytics = require('react-ga')
const config = require('./config/meta')

/**
 * Initialize Google Analytics
 */
exports.onClientEntry = () => {
  GoogleAnalytics.initialize(config.googleAnalyticsId)
}

exports.onInitialClientRender = () => {
  // Check CookieConsent component
  // eslint-disable-next-line func-names
  window.addEventListener('load', function() {
    // eslint-disable-next-line no-unused-expressions
    window.cookieconsent &&
      window.cookieconsent.initialise({
        palette: {
          popup: {
            background: '#f4f4f4',
            text: '#786b61',
          },
          button: {
            background: '#f5831a',
            text: '#ffffff',
          },
        },
        theme: 'classic',
        content: {
          message:
            'Този сайт използва „бисквитки“, за да бъде по-функционален и ефективен. Продължавайки да ползвате този уебсайт, Вие се съгласявате с нашата Декларация за поверителност и Политика за ползване на „бисквитки“.',
          dismiss: 'Съгласен съм',
          link: 'Научете повече',
        },
      })
  })
}
