module.exports = {
  siteUrl: `https://www.roditeli.org`,
  siteTitle: `Асоциация Родители`,
  siteTitleAlt: `Асоциация Родители`,
  siteTitleShort: `Родители`,
  siteDescription: `Асоциация Родители - Да насърчим родителите да бъдат най-добрите родители, които могат да бъдат, и да подкрепим децата да се превърнат в най-добрите възрастни, които могат да станат.`,
  manifest: {
    themeColor: `#253688`,
    backgroundColor: `#253688`,
  },
  googleAnalyticsId: `UA-54942388-2`,
  facebook: `Your optional Facebook App ID`,
  twitter: `Your Twitter username`,
  schema: {
    author: `Jane Doe`,
  },
}
